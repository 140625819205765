import * as React from 'react'
import Layout from '../components/layout'
import { Link, graphql } from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import parse from 'html-react-parser'

const SingleProduct = ({ data }) => {
    if(!data) return null
    const prodotto = data.prismicHardware
    const image = getImage(prodotto.data.immagine.localFile)

    return (
        <Layout>
            <section className="px-6 py-12">
                <div className="container mx-auto">
                    <div className="flex gap-12 flex-wrap-reverse">
                        
                        <div className="flex-1">
                            <span className="tag">{prodotto.type}</span>
                            <h1 className=" text-persian-green-500 text-4xl mb-6">{prodotto.data.titolo.text}</h1>
                            <div className="prose prose-persian-green max-w-none">
                                {parse(prodotto.data.descrizione.html)}
                            </div>
                        </div>
                        <div className="lg:w-3/5">
                            <GatsbyImage image={image} />
                        </div>
                    </div>
                </div>
            </section >
        </Layout >
    )
}

export default SingleProduct

export const pageQuery = graphql`
  query HardwareBySlug($uid: String!) {
    prismicHardware(uid: { eq: $uid }) {
      uid
      data {
        titolo {
          text
        }
        descrizione {
          html
        }
        immagine {
            localFile {
                childImageSharp {
                    gatsbyImageData (
                        placeholder: BLURRED
                    )
                }
            }
        }
      }
      type
    }
  }
`